@use "src/styles/variables/colors" as colors;

.EditPatient {
  padding: 32px;
}

.CheckboxAlignRight {
  text-align: right;

  input {
    margin: 4px 0 4px auto;
  }
}

.ButtonAlignRight {
  text-align: right;
  button {
    margin-top: 12px;
  }
}

.Spacer {
  height: 48px;
}

.Thumbnail {
  width: 64px;
  height: 64px;
  object-fit: cover;
}

.TopBar {
  position: fixed;
  left: 230px;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: colors.$dark-gray;
  color: colors.$white;
  z-index: 500;

  .StatusInfo {
    flex-grow: 1;
    padding: 24px;
    text-align: right;
  }

  .PatientData {
    padding: 24px;
  }

  .SaveButton {
    padding: 24px;

    button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
