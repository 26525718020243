.navigation_container {
    position:absolute;
     top: 54px;
}

.sidebar_footer_container {
    color: #f6f8f9;
    display: flex;
    justify-content: center;
    padding-top: 9rem;
    border-top: 1px solid var(--asana-active-bar);
  }

  .sidebar_content_container {
    margin: 5px 0 5px 0;
    height: calc(100% - 160px);
    overflow-x: hidden;
    overflow-y: auto;
   }

  .details_container {
    left: 320px;
    padding: 1.5rem;
  }

  .details_container {
    width: calc(100% - 300px);
    min-width: 990px;
    position:absolute;
    left:240px;
  }

  .ui.thin.left.sidebar, .ui.thin.right.sidebar {
    width: 230px;
}

  .ui.table thead tr:first-child > th {
  position: sticky !important;
  top: 0;
  z-index: 2;
}

.ui.horizontal.segments>.segment {
  // min-width: 15rem;
  width: 20rem;
  // max-width: 15rem;
}

.practicesSegment1 {
  max-width: 13rem;
}
.practicesSegment2 {
  max-width: 30rem;
}

.verticalCenter {
  align-content: center;
}

.ui.horizontal.segments {
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.ui.progress .bar>.progress {
  color: white;
}

.ui.progress {
margin-bottom: 0.5rem;
margin-top: 0.5rem;
}

.ui.progress:last-child {
  margin-bottom: 0.1rem;
  margin-top: 0.5rem;
  }

.progress {
  background-color: transparent !important;
}

.loading-spinner {
  margin-top: 25rem !important;
}

.list-doctor-appointment {
height: auto;
overflow: overlay;
}

.segment-style {
  background-color: #e1f0ff !important;
  color: #1a6fc8 !important;
}

.segment-style-close {
  background-color: #E8E8E8 !important;
  color: #1a6fc8 !important;
}

.onlineTextcolorRed {
  color: #C31A1B;
}

.onlineTextcolorGreen {
  color: #3FB383;
}

.onlineTextcolorBlue {
  color: #1A6EC8;
}

.progressTitle {
  color: #1A6EC8;
  margin-bottom: 1rem;
}

.detailsPractiseBotton {
  margin-top: 1rem !important;
}

// LOGIN PAGE CSS

.footer {
  margin-top: auto;
  color: grey;
  padding: 5px 15px;
  height: 40px;
  /* background-color: cornflowerblue; */
  display: flex;
  justify-content: space-between;
}

.login_page_container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.login_form_container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: chocolate; */
  height: calc(100vh - 94px);
}

.login_form_wrapper {
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-height: 600px) {
  .ui.header.login_welcome {
   display: none;
  }
}

.login_form {
  min-width: 300px;
}

.loginbtn {
  background-color: #3db384 !important;
  color: white !important;
}